import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ResponsiveImage',
    props: {
        s: { type: String },
        m: { type: String },
        l: { type: String },
        xl: { type: String },
    },
});
